/*
  This file define all variables used wihtin the rest of the CSS
*/

// System colors to use from now
$color-background: var(--sys-color-background);
$color-on-background: var(--sys-color-on-background);
// Darker Brown
$color-surface: var(--sys-color-surface);
$color-on-surface: var(--sys-color-on-surface);
// Dark brown
$color-primary: var(--sys-color-primary);
$color-on-primary: var(--sys-color-on-primary);
// Gold
$color-secondary: var(--sys-color-secondary);
$color-on-secondary: var(--sys-color-on-secondary);

$color-secondary-variant: var(--sys-color-secondary-variant);
$color-on-secondary-variant: var(--sys-color-on-secondary-variant);

$color-shadow: var(--sys-color-shadow);

// General variables
$font_size: 16px;

// Screen Size
$container_width: 1200px;

// z-index
$zindex_wrapper: 1;
$zindex_header: 10;
$zindex_modal: 500;
$zindex_navigation: 100;

// Mixins used in the project
@mixin sansserif {
  font-family: var(--sys-typeface-sans);

  // If browser support variableFonts, use it
  @supports (font-variation-settings: "wdth" 115) {
    font-family: var(--sys-typeface-sans-variable);
  }
}

@mixin serif {
  font-family: var(--sys-typeface-serif);
  font-variation-settings: 'opsz' 24;

  // If browser support variableFonts, use it
  @supports (font-variation-settings: "wdth" 115) {
    font-family: var(--sys-typeface-serif-variable);
  }
}

// Mixins to better understand responsive breaking points
@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-down {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 899px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-down {
  @media (max-width: 1200px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-down {
  @media (max-width: 1799px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
